:root {
  --fontJakarta--: 'Plus Jakarta Sans', sans-serif;

}

body {
  margin: 0;
  font-family: var(--fontJakarta--) !important;
  background: #0B101D !important;
}

code {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
